import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatashareService {
  public json_islogin = {
    "username":"",
    "access":"",
    "time": ""
  }
  public isUserLoggedIn: BehaviorSubject<any> = new BehaviorSubject<any>(this.json_islogin);
  constructor() { }
}
